@font-face {
font-family: "uicons-regular-rounded";
src: url("./webfonts/uicons-regular-rounded.eot#iefix") format("embedded-opentype"),
url("./webfonts/uicons-regular-rounded.woff2") format("woff2"),
url("./webfonts/uicons-regular-rounded.woff") format("woff");
}

    i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
font-family: uicons-regular-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-rr-add:before {
    content: "\f101";
    }
        .fi-rr-alarm-clock:before {
    content: "\f102";
    }
        .fi-rr-align-center:before {
    content: "\f103";
    }
        .fi-rr-align-justify:before {
    content: "\f104";
    }
        .fi-rr-align-left:before {
    content: "\f105";
    }
        .fi-rr-align-right:before {
    content: "\f106";
    }
        .fi-rr-ambulance:before {
    content: "\f107";
    }
        .fi-rr-angle-double-left:before {
    content: "\f108";
    }
        .fi-rr-angle-double-right:before {
    content: "\f109";
    }
        .fi-rr-angle-double-small-left:before {
    content: "\f10a";
    }
        .fi-rr-angle-double-small-right:before {
    content: "\f10b";
    }
        .fi-rr-angle-down:before {
    content: "\f10c";
    }
        .fi-rr-angle-left:before {
    content: "\f10d";
    }
        .fi-rr-angle-right:before {
    content: "\f10e";
    }
        .fi-rr-angle-small-down:before {
    content: "\f10f";
    }
        .fi-rr-angle-small-left:before {
    content: "\f110";
    }
        .fi-rr-angle-small-right:before {
    content: "\f111";
    }
        .fi-rr-angle-small-up:before {
    content: "\f112";
    }
        .fi-rr-angle-up:before {
    content: "\f113";
    }
        .fi-rr-apps-add:before {
    content: "\f114";
    }
        .fi-rr-apps-delete:before {
    content: "\f115";
    }
        .fi-rr-apps-sort:before {
    content: "\f116";
    }
        .fi-rr-apps:before {
    content: "\f117";
    }
        .fi-rr-archive:before {
    content: "\f118";
    }
        .fi-rr-arrow-down:before {
    content: "\f119";
    }
        .fi-rr-arrow-left:before {
    content: "\f11a";
    }
        .fi-rr-arrow-right:before {
    content: "\f11b";
    }
        .fi-rr-arrow-small-down:before {
    content: "\f11c";
    }
        .fi-rr-arrow-small-left:before {
    content: "\f11d";
    }
        .fi-rr-arrow-small-right:before {
    content: "\f11e";
    }
        .fi-rr-arrow-small-up:before {
    content: "\f11f";
    }
        .fi-rr-arrow-up:before {
    content: "\f120";
    }
        .fi-rr-asterik:before {
    content: "\f121";
    }
        .fi-rr-backpack:before {
    content: "\f122";
    }
        .fi-rr-badge:before {
    content: "\f123";
    }
        .fi-rr-balloons:before {
    content: "\f124";
    }
        .fi-rr-ban:before {
    content: "\f125";
    }
        .fi-rr-band-aid:before {
    content: "\f126";
    }
        .fi-rr-bank:before {
    content: "\f127";
    }
        .fi-rr-barber-shop:before {
    content: "\f128";
    }
        .fi-rr-baseball:before {
    content: "\f129";
    }
        .fi-rr-basketball:before {
    content: "\f12a";
    }
        .fi-rr-bell-ring:before {
    content: "\f12b";
    }
        .fi-rr-bell-school:before {
    content: "\f12c";
    }
        .fi-rr-bell:before {
    content: "\f12d";
    }
        .fi-rr-billiard:before {
    content: "\f12e";
    }
        .fi-rr-bold:before {
    content: "\f12f";
    }
        .fi-rr-book-alt:before {
    content: "\f130";
    }
        .fi-rr-book:before {
    content: "\f131";
    }
        .fi-rr-bookmark:before {
    content: "\f132";
    }
        .fi-rr-bowling:before {
    content: "\f133";
    }
        .fi-rr-box-alt:before {
    content: "\f134";
    }
        .fi-rr-box:before {
    content: "\f135";
    }
        .fi-rr-briefcase:before {
    content: "\f136";
    }
        .fi-rr-broom:before {
    content: "\f137";
    }
        .fi-rr-browser:before {
    content: "\f138";
    }
        .fi-rr-brush:before {
    content: "\f139";
    }
        .fi-rr-bug:before {
    content: "\f13a";
    }
        .fi-rr-building:before {
    content: "\f13b";
    }
        .fi-rr-bulb:before {
    content: "\f13c";
    }
        .fi-rr-cake-birthday:before {
    content: "\f13d";
    }
        .fi-rr-cake-wedding:before {
    content: "\f13e";
    }
        .fi-rr-calculator:before {
    content: "\f13f";
    }
        .fi-rr-calendar:before {
    content: "\f140";
    }
        .fi-rr-camera:before {
    content: "\f141";
    }
        .fi-rr-caret-down:before {
    content: "\f142";
    }
        .fi-rr-caret-left:before {
    content: "\f143";
    }
        .fi-rr-caret-right:before {
    content: "\f144";
    }
        .fi-rr-caret-up:before {
    content: "\f145";
    }
        .fi-rr-check:before {
    content: "\f146";
    }
        .fi-rr-checkbox:before {
    content: "\f147";
    }
        .fi-rr-chess-piece:before {
    content: "\f148";
    }
        .fi-rr-circle-small:before {
    content: "\f149";
    }
        .fi-rr-circle:before {
    content: "\f14a";
    }
        .fi-rr-clip:before {
    content: "\f14b";
    }
        .fi-rr-clock:before {
    content: "\f14c";
    }
        .fi-rr-cloud-check:before {
    content: "\f14d";
    }
        .fi-rr-cloud-disabled:before {
    content: "\f14e";
    }
        .fi-rr-cloud-download:before {
    content: "\f14f";
    }
        .fi-rr-cloud-share:before {
    content: "\f150";
    }
        .fi-rr-cloud-upload:before {
    content: "\f151";
    }
        .fi-rr-cloud:before {
    content: "\f152";
    }
        .fi-rr-comment-alt:before {
    content: "\f153";
    }
        .fi-rr-comment:before {
    content: "\f154";
    }
        .fi-rr-compress-alt:before {
    content: "\f155";
    }
        .fi-rr-compress:before {
    content: "\f156";
    }
        .fi-rr-computer:before {
    content: "\f157";
    }
        .fi-rr-confetti:before {
    content: "\f158";
    }
        .fi-rr-cookie:before {
    content: "\f159";
    }
        .fi-rr-copy-alt:before {
    content: "\f15a";
    }
        .fi-rr-copy:before {
    content: "\f15b";
    }
        .fi-rr-copyright:before {
    content: "\f15c";
    }
        .fi-rr-cream:before {
    content: "\f15d";
    }
        .fi-rr-credit-card:before {
    content: "\f15e";
    }
        .fi-rr-cross-circle:before {
    content: "\f15f";
    }
        .fi-rr-cross-small:before {
    content: "\f160";
    }
        .fi-rr-cross:before {
    content: "\f161";
    }
        .fi-rr-crown:before {
    content: "\f162";
    }
        .fi-rr-cursor-finger:before {
    content: "\f163";
    }
        .fi-rr-cursor-plus:before {
    content: "\f164";
    }
        .fi-rr-cursor-text-alt:before {
    content: "\f165";
    }
        .fi-rr-cursor-text:before {
    content: "\f166";
    }
        .fi-rr-cursor:before {
    content: "\f167";
    }
        .fi-rr-dart:before {
    content: "\f168";
    }
        .fi-rr-dashboard:before {
    content: "\f169";
    }
        .fi-rr-data-transfer:before {
    content: "\f16a";
    }
        .fi-rr-database:before {
    content: "\f16b";
    }
        .fi-rr-delete:before {
    content: "\f16c";
    }
        .fi-rr-diamond:before {
    content: "\f16d";
    }
        .fi-rr-dice:before {
    content: "\f16e";
    }
        .fi-rr-diploma:before {
    content: "\f16f";
    }
        .fi-rr-disco-ball:before {
    content: "\f170";
    }
        .fi-rr-disk:before {
    content: "\f171";
    }
        .fi-rr-doctor:before {
    content: "\f172";
    }
        .fi-rr-document-signed:before {
    content: "\f173";
    }
        .fi-rr-document:before {
    content: "\f174";
    }
        .fi-rr-dollar:before {
    content: "\f175";
    }
        .fi-rr-download:before {
    content: "\f176";
    }
        .fi-rr-duplicate:before {
    content: "\f177";
    }
        .fi-rr-e-learning:before {
    content: "\f178";
    }
        .fi-rr-earnings:before {
    content: "\f179";
    }
        .fi-rr-edit-alt:before {
    content: "\f17a";
    }
        .fi-rr-edit:before {
    content: "\f17b";
    }
        .fi-rr-envelope:before {
    content: "\f17c";
    }
        .fi-rr-euro:before {
    content: "\f17d";
    }
        .fi-rr-exclamation:before {
    content: "\f17e";
    }
        .fi-rr-expand:before {
    content: "\f17f";
    }
        .fi-rr-eye-crossed:before {
    content: "\f180";
    }
        .fi-rr-eye-dropper:before {
    content: "\f181";
    }
        .fi-rr-eye:before {
    content: "\f182";
    }
        .fi-rr-feather:before {
    content: "\f183";
    }
        .fi-rr-ferris-wheel:before {
    content: "\f184";
    }
        .fi-rr-file-add:before {
    content: "\f185";
    }
        .fi-rr-file-ai:before {
    content: "\f186";
    }
        .fi-rr-file-check:before {
    content: "\f187";
    }
        .fi-rr-file-delete:before {
    content: "\f188";
    }
        .fi-rr-file-eps:before {
    content: "\f189";
    }
        .fi-rr-file-gif:before {
    content: "\f18a";
    }
        .fi-rr-file-music:before {
    content: "\f18b";
    }
        .fi-rr-file-psd:before {
    content: "\f18c";
    }
        .fi-rr-file:before {
    content: "\f18d";
    }
        .fi-rr-fill:before {
    content: "\f18e";
    }
        .fi-rr-film:before {
    content: "\f18f";
    }
        .fi-rr-filter:before {
    content: "\f190";
    }
        .fi-rr-fingerprint:before {
    content: "\f191";
    }
        .fi-rr-flag:before {
    content: "\f192";
    }
        .fi-rr-flame:before {
    content: "\f193";
    }
        .fi-rr-flip-horizontal:before {
    content: "\f194";
    }
        .fi-rr-flower-bouquet:before {
    content: "\f195";
    }
        .fi-rr-folder-add:before {
    content: "\f196";
    }
        .fi-rr-folder:before {
    content: "\f197";
    }
        .fi-rr-following:before {
    content: "\f198";
    }
        .fi-rr-football:before {
    content: "\f199";
    }
        .fi-rr-form:before {
    content: "\f19a";
    }
        .fi-rr-forward:before {
    content: "\f19b";
    }
        .fi-rr-ftp:before {
    content: "\f19c";
    }
        .fi-rr-gallery:before {
    content: "\f19d";
    }
        .fi-rr-gamepad:before {
    content: "\f19e";
    }
        .fi-rr-gem:before {
    content: "\f19f";
    }
        .fi-rr-gift:before {
    content: "\f1a0";
    }
        .fi-rr-glass-cheers:before {
    content: "\f1a1";
    }
        .fi-rr-glasses:before {
    content: "\f1a2";
    }
        .fi-rr-globe-alt:before {
    content: "\f1a3";
    }
        .fi-rr-globe:before {
    content: "\f1a4";
    }
        .fi-rr-golf:before {
    content: "\f1a5";
    }
        .fi-rr-graduation-cap:before {
    content: "\f1a6";
    }
        .fi-rr-graphic-tablet:before {
    content: "\f1a7";
    }
        .fi-rr-grid-alt:before {
    content: "\f1a8";
    }
        .fi-rr-grid:before {
    content: "\f1a9";
    }
        .fi-rr-guitar:before {
    content: "\f1aa";
    }
        .fi-rr-gym:before {
    content: "\f1ab";
    }
        .fi-rr-hat-birthday:before {
    content: "\f1ac";
    }
        .fi-rr-headphones:before {
    content: "\f1ad";
    }
        .fi-rr-headset:before {
    content: "\f1ae";
    }
        .fi-rr-heart:before {
    content: "\f1af";
    }
        .fi-rr-home:before {
    content: "\f1b0";
    }
        .fi-rr-hourglass-end:before {
    content: "\f1b1";
    }
        .fi-rr-hourglass:before {
    content: "\f1b2";
    }
        .fi-rr-ice-skate:before {
    content: "\f1b3";
    }
        .fi-rr-id-badge:before {
    content: "\f1b4";
    }
        .fi-rr-inbox:before {
    content: "\f1b5";
    }
        .fi-rr-incognito:before {
    content: "\f1b6";
    }
        .fi-rr-indent:before {
    content: "\f1b7";
    }
        .fi-rr-infinity:before {
    content: "\f1b8";
    }
        .fi-rr-info:before {
    content: "\f1b9";
    }
        .fi-rr-interactive:before {
    content: "\f1ba";
    }
        .fi-rr-interlining:before {
    content: "\f1bb";
    }
        .fi-rr-interrogation:before {
    content: "\f1bc";
    }
        .fi-rr-italic:before {
    content: "\f1bd";
    }
        .fi-rr-jpg:before {
    content: "\f1be";
    }
        .fi-rr-key:before {
    content: "\f1bf";
    }
        .fi-rr-keyboard:before {
    content: "\f1c0";
    }
        .fi-rr-kite:before {
    content: "\f1c1";
    }
        .fi-rr-label:before {
    content: "\f1c2";
    }
        .fi-rr-laptop:before {
    content: "\f1c3";
    }
        .fi-rr-lasso:before {
    content: "\f1c4";
    }
        .fi-rr-layers:before {
    content: "\f1c5";
    }
        .fi-rr-layout-fluid:before {
    content: "\f1c6";
    }
        .fi-rr-letter-case:before {
    content: "\f1c7";
    }
        .fi-rr-life-ring:before {
    content: "\f1c8";
    }
        .fi-rr-line-width:before {
    content: "\f1c9";
    }
        .fi-rr-link:before {
    content: "\f1ca";
    }
        .fi-rr-lipstick:before {
    content: "\f1cb";
    }
        .fi-rr-list-check:before {
    content: "\f1cc";
    }
        .fi-rr-list:before {
    content: "\f1cd";
    }
        .fi-rr-location-alt:before {
    content: "\f1ce";
    }
        .fi-rr-lock-alt:before {
    content: "\f1cf";
    }
        .fi-rr-lock:before {
    content: "\f1d0";
    }
        .fi-rr-magic-wand:before {
    content: "\f1d1";
    }
        .fi-rr-makeup-brush:before {
    content: "\f1d2";
    }
        .fi-rr-marker-time:before {
    content: "\f1d3";
    }
        .fi-rr-marker:before {
    content: "\f1d4";
    }
        .fi-rr-mask-carnival:before {
    content: "\f1d5";
    }
        .fi-rr-medicine:before {
    content: "\f1d6";
    }
        .fi-rr-megaphone:before {
    content: "\f1d7";
    }
        .fi-rr-menu-burger:before {
    content: "\f1d8";
    }
        .fi-rr-menu-dots-vertical:before {
    content: "\f1d9";
    }
        .fi-rr-menu-dots:before {
    content: "\f1da";
    }
        .fi-rr-microphone-alt:before {
    content: "\f1db";
    }
        .fi-rr-microphone:before {
    content: "\f1dc";
    }
        .fi-rr-minus-small:before {
    content: "\f1dd";
    }
        .fi-rr-minus:before {
    content: "\f1de";
    }
        .fi-rr-mobile:before {
    content: "\f1df";
    }
        .fi-rr-mode-landscape:before {
    content: "\f1e0";
    }
        .fi-rr-mode-portrait:before {
    content: "\f1e1";
    }
        .fi-rr-money:before {
    content: "\f1e2";
    }
        .fi-rr-mouse:before {
    content: "\f1e3";
    }
        .fi-rr-music-alt:before {
    content: "\f1e4";
    }
        .fi-rr-music:before {
    content: "\f1e5";
    }
        .fi-rr-network-cloud:before {
    content: "\f1e6";
    }
        .fi-rr-network:before {
    content: "\f1e7";
    }
        .fi-rr-notebook:before {
    content: "\f1e8";
    }
        .fi-rr-opacity:before {
    content: "\f1e9";
    }
        .fi-rr-package:before {
    content: "\f1ea";
    }
        .fi-rr-paint-brush:before {
    content: "\f1eb";
    }
        .fi-rr-palette:before {
    content: "\f1ec";
    }
        .fi-rr-paper-plane:before {
    content: "\f1ed";
    }
        .fi-rr-password:before {
    content: "\f1ee";
    }
        .fi-rr-pause:before {
    content: "\f1ef";
    }
        .fi-rr-pencil:before {
    content: "\f1f0";
    }
        .fi-rr-pharmacy:before {
    content: "\f1f1";
    }
        .fi-rr-physics:before {
    content: "\f1f2";
    }
        .fi-rr-picture:before {
    content: "\f1f3";
    }
        .fi-rr-ping-pong:before {
    content: "\f1f4";
    }
        .fi-rr-play-alt:before {
    content: "\f1f5";
    }
        .fi-rr-play:before {
    content: "\f1f6";
    }
        .fi-rr-playing-cards:before {
    content: "\f1f7";
    }
        .fi-rr-plus-small:before {
    content: "\f1f8";
    }
        .fi-rr-plus:before {
    content: "\f1f9";
    }
        .fi-rr-poker-chip:before {
    content: "\f1fa";
    }
        .fi-rr-portrait:before {
    content: "\f1fb";
    }
        .fi-rr-pound:before {
    content: "\f1fc";
    }
        .fi-rr-power:before {
    content: "\f1fd";
    }
        .fi-rr-presentation:before {
    content: "\f1fe";
    }
        .fi-rr-print:before {
    content: "\f1ff";
    }
        .fi-rr-protractor:before {
    content: "\f200";
    }
        .fi-rr-pulse:before {
    content: "\f201";
    }
        .fi-rr-quote-right:before {
    content: "\f202";
    }
        .fi-rr-rec:before {
    content: "\f203";
    }
        .fi-rr-receipt:before {
    content: "\f204";
    }
        .fi-rr-record-vinyl:before {
    content: "\f205";
    }
        .fi-rr-rectabgle-vertical:before {
    content: "\f206";
    }
        .fi-rr-rectangle-horizontal:before {
    content: "\f207";
    }
        .fi-rr-rectangle-panoramic:before {
    content: "\f208";
    }
        .fi-rr-redo:before {
    content: "\f209";
    }
        .fi-rr-reflect:before {
    content: "\f20a";
    }
        .fi-rr-refresh:before {
    content: "\f20b";
    }
        .fi-rr-resize:before {
    content: "\f20c";
    }
        .fi-rr-resources:before {
    content: "\f20d";
    }
        .fi-rr-rewind:before {
    content: "\f20e";
    }
        .fi-rr-rings-wedding:before {
    content: "\f20f";
    }
        .fi-rr-rocket:before {
    content: "\f210";
    }
        .fi-rr-rotate-right:before {
    content: "\f211";
    }
        .fi-rr-rugby:before {
    content: "\f212";
    }
        .fi-rr-scale:before {
    content: "\f213";
    }
        .fi-rr-school-bus:before {
    content: "\f214";
    }
        .fi-rr-school:before {
    content: "\f215";
    }
        .fi-rr-scissors:before {
    content: "\f216";
    }
        .fi-rr-screen:before {
    content: "\f217";
    }
        .fi-rr-search-alt:before {
    content: "\f218";
    }
        .fi-rr-search:before {
    content: "\f219";
    }
        .fi-rr-settings-sliders:before {
    content: "\f21a";
    }
        .fi-rr-settings:before {
    content: "\f21b";
    }
        .fi-rr-share:before {
    content: "\f21c";
    }
        .fi-rr-shield-check:before {
    content: "\f21d";
    }
        .fi-rr-shield-exclamation:before {
    content: "\f21e";
    }
        .fi-rr-shield-interrogation:before {
    content: "\f21f";
    }
        .fi-rr-shield-plus:before {
    content: "\f220";
    }
        .fi-rr-shield:before {
    content: "\f221";
    }
        .fi-rr-shop:before {
    content: "\f222";
    }
        .fi-rr-shopping-bag-add:before {
    content: "\f223";
    }
        .fi-rr-shopping-bag:before {
    content: "\f224";
    }
        .fi-rr-shopping-cart-add:before {
    content: "\f225";
    }
        .fi-rr-shopping-cart-check:before {
    content: "\f226";
    }
        .fi-rr-shopping-cart:before {
    content: "\f227";
    }
        .fi-rr-shuffle:before {
    content: "\f228";
    }
        .fi-rr-sign-in:before {
    content: "\f229";
    }
        .fi-rr-sign-out:before {
    content: "\f22a";
    }
        .fi-rr-signal-alt-1:before {
    content: "\f22b";
    }
        .fi-rr-signal-alt-2:before {
    content: "\f22c";
    }
        .fi-rr-signal-alt:before {
    content: "\f22d";
    }
        .fi-rr-skateboard:before {
    content: "\f22e";
    }
        .fi-rr-smartphone:before {
    content: "\f22f";
    }
        .fi-rr-soap:before {
    content: "\f230";
    }
        .fi-rr-spa:before {
    content: "\f231";
    }
        .fi-rr-speaker:before {
    content: "\f232";
    }
        .fi-rr-spinner-alt:before {
    content: "\f233";
    }
        .fi-rr-spinner:before {
    content: "\f234";
    }
        .fi-rr-square-root:before {
    content: "\f235";
    }
        .fi-rr-square:before {
    content: "\f236";
    }
        .fi-rr-star:before {
    content: "\f237";
    }
        .fi-rr-stats:before {
    content: "\f238";
    }
        .fi-rr-stethoscope:before {
    content: "\f239";
    }
        .fi-rr-sticker:before {
    content: "\f23a";
    }
        .fi-rr-stop:before {
    content: "\f23b";
    }
        .fi-rr-stopwatch:before {
    content: "\f23c";
    }
        .fi-rr-subtitles:before {
    content: "\f23d";
    }
        .fi-rr-surfing:before {
    content: "\f23e";
    }
        .fi-rr-sword:before {
    content: "\f23f";
    }
        .fi-rr-syringe:before {
    content: "\f240";
    }
        .fi-rr-tablet:before {
    content: "\f241";
    }
        .fi-rr-target:before {
    content: "\f242";
    }
        .fi-rr-tennis:before {
    content: "\f243";
    }
        .fi-rr-terrace:before {
    content: "\f244";
    }
        .fi-rr-test-tube:before {
    content: "\f245";
    }
        .fi-rr-test:before {
    content: "\f246";
    }
        .fi-rr-text-check:before {
    content: "\f247";
    }
        .fi-rr-text:before {
    content: "\f248";
    }
        .fi-rr-thumbs-down:before {
    content: "\f249";
    }
        .fi-rr-thumbs-up:before {
    content: "\f24a";
    }
        .fi-rr-ticket:before {
    content: "\f24b";
    }
        .fi-rr-time-add:before {
    content: "\f24c";
    }
        .fi-rr-time-check:before {
    content: "\f24d";
    }
        .fi-rr-time-delete:before {
    content: "\f24e";
    }
        .fi-rr-time-fast:before {
    content: "\f24f";
    }
        .fi-rr-time-forward-sixty:before {
    content: "\f250";
    }
        .fi-rr-time-forward-ten:before {
    content: "\f251";
    }
        .fi-rr-time-forward:before {
    content: "\f252";
    }
        .fi-rr-time-half-past:before {
    content: "\f253";
    }
        .fi-rr-time-oclock:before {
    content: "\f254";
    }
        .fi-rr-time-past:before {
    content: "\f255";
    }
        .fi-rr-time-quarter-past:before {
    content: "\f256";
    }
        .fi-rr-time-quarter-to:before {
    content: "\f257";
    }
        .fi-rr-time-twenty-four:before {
    content: "\f258";
    }
        .fi-rr-tool-crop:before {
    content: "\f259";
    }
        .fi-rr-tool-marquee:before {
    content: "\f25a";
    }
        .fi-rr-tooth:before {
    content: "\f25b";
    }
        .fi-rr-transform:before {
    content: "\f25c";
    }
        .fi-rr-trash:before {
    content: "\f25d";
    }
        .fi-rr-treatment:before {
    content: "\f25e";
    }
        .fi-rr-tree-christmas:before {
    content: "\f25f";
    }
        .fi-rr-trophy:before {
    content: "\f260";
    }
        .fi-rr-umbrella:before {
    content: "\f261";
    }
        .fi-rr-underline:before {
    content: "\f262";
    }
        .fi-rr-undo:before {
    content: "\f263";
    }
        .fi-rr-unlock:before {
    content: "\f264";
    }
        .fi-rr-upload:before {
    content: "\f265";
    }
        .fi-rr-usb-pendrive:before {
    content: "\f266";
    }
        .fi-rr-user-add:before {
    content: "\f267";
    }
        .fi-rr-user-delete:before {
    content: "\f268";
    }
        .fi-rr-user-remove:before {
    content: "\f269";
    }
        .fi-rr-user-time:before {
    content: "\f26a";
    }
        .fi-rr-user:before {
    content: "\f26b";
    }
        .fi-rr-vector-alt:before {
    content: "\f26c";
    }
        .fi-rr-vector:before {
    content: "\f26d";
    }
        .fi-rr-video-camera:before {
    content: "\f26e";
    }
        .fi-rr-volleyball:before {
    content: "\f26f";
    }
        .fi-rr-volume:before {
    content: "\f270";
    }
        .fi-rr-wifi-alt:before {
    content: "\f271";
    }
        .fi-rr-world:before {
    content: "\f272";
    }
        .fi-rr-yen:before {
    content: "\f273";
    }
        .fi-rr-zoom-in:before {
    content: "\f274";
    }
        .fi-rr-zoom-out:before {
    content: "\f275";
    }
